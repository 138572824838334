import './CloudMarketplace.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import ProduceIcon from './assets/produce.svg';
import RegisterIcon from './assets/register.svg';
import ListIcon from './assets/list.svg';
import PayIcon from './assets/pay.svg';

interface StepProps {
  title: string;
  icon: string;
}

const Step: React.FC<StepProps> = (props) => {
  const { title, icon } = props;

  return (
    <div className="TDB-SaasPage__Marketplace__Step">
      <img alt={title} src={icon} />
      <Typography color="neutral-600" fontWeight="bold">
        {title}
      </Typography>
    </div>
  );
};

const CloudMarketplace: React.FC = () => {
  return (
    <Container>
      <div className="TDB-SaasPage__Marketplace">
        <div className="TDB-SaasPage__Marketplace__text">
          <Typography fontSize="heading-1" as="h3" fontWeight="bold">
            Deliver data products to <span className="TDB-SaasPage__Marketplace__text__new-markets">new markets</span>
          </Typography>
          <Typography as="p" fontWeight="medium" color="neutral-600">
            Expand your reach by using TileDB Cloud SaaS to launch your next data marketplace. The same access control and usage-based
            billing features that let users explore and analyze public data assets can also be used to sell proprietary data and code
            assets. Simply connect your Stripe account and set your price. You’re one step away from reaching a global audience of data
            consumers, without having to copy and re-host data products.
          </Typography>
        </div>
        <div className="TDB-SaasPage__Marketplace__steps">
          <Step title="Produce" icon={ProduceIcon} />
          <Step title="Register" icon={RegisterIcon} />
          <Step title="List to marketplace" icon={ListIcon} />
          <Step title="Users pay for access" icon={PayIcon} />
        </div>
      </div>
    </Container>
  );
};

export default CloudMarketplace;
