import * as React from 'react';
import Button from '@components/Button';
import Container from '@components/Container';
import Typography from '@components/Typography';
import links from '@constants/links';
import saasImg from './no_infrastructure.png';
import './CloudSaas.scss';

const CloudSaas: React.FC = () => {
  return (
    <Container>
      <div className="TDB-CloudSaas">
        <div className="TDB-CloudSaas__wrapper">
          <Typography as="h2" className="TDB-CloudSaas__title" color="brand-100" fontSize="display-2">
            No infrastructure hassles, pay as you go
          </Typography>
          <Typography as="p" className="TDB-CloudSaas__text" color="brand-100" fontSize="subheading-2">
            TileDB Cloud SaaS inherits all of the core database capabilities that make TileDB uniquely suited for analysis-ready data, but
            delivered as a managed service that promotes security and collaboration, eliminating infrastructure overhead and offering
            massive TCO savings.
          </Typography>
          <div className="TDB-CloudSaas__btn-container">
            <Button className="TDB-CloudSaas__btn" to={`${links.cloud}`} primary>
              TileDB Cloud capabilities
            </Button>
          </div>
        </div>
        <img src={saasImg} alt="no infrastructure" className="TDB-CloudSaas__img" />
      </div>
    </Container>
  );
};

export default CloudSaas;
