import classNames from 'classnames';
import * as React from 'react';
import links from '@constants/links';
import Button from '../Button';
import Container from '../Container';
import Typography from '../Typography';
import overviewImg from './Overview.jpg';
import './CloudInAction.scss';

interface CloudInActionProps {
  title?: string;
  cta?: {
    text: string;
    to?: string;
    href?: string;
  };
  className?: string;
}

const CloudInAction: React.FC<CloudInActionProps> = (props) => {
  const { cta, title = 'Want to see TileDB in action?', className } = props;
  const defaultCta = {
    text: 'Schedule a demo',
    to: links.requestDemo,
    href: '',
  };

  const ctaButton = cta || defaultCta;

  return (
    <Container className={classNames('TDB-CloudInAction', className)}>
      <Typography
        className="TDB-CloudInAction__title mb-8"
        fontSize="heading-1"
        color="neutral-800"
        as="h2"
        align="center"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <div className="TDB-CloudInAction__btn-container">
        <Button primary className="TDB-CloudInAction__btn" to={ctaButton.to} href={ctaButton.href}>
          {ctaButton.text}
        </Button>
      </div>
      <div className="TDB-CloudInAction__img-container">
        <img src={overviewImg} className="TDB-CloudInAction__img" alt="organizations" />
      </div>
    </Container>
  );
};

export default CloudInAction;
