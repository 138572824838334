import './SaasDataGovernance.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import DataGovernanceImage from './assets/data-governance-lock.png';

const SaasDataGovernance: React.FC = () => {
  return (
    <Container>
      <div className="TDB-SaasPage__DataGovernance">
        <div className="TDB-SaasPage__DataGovernance__Image">
          <img alt="Data governance" src={DataGovernanceImage} />
        </div>
        <div className="TDB-SaasPage__DataGovernance__Text">
          <Typography as="h3" fontWeight="bold" fontSize="heading-2" color="neutral-600">
            Ensure <span className="TDB-SaasPage__DataGovernance__Text__DataGovernance">data governance</span> for cloud compliance
          </Typography>
          <Typography as="p" fontWeight="medium" color="neutral-600" fontSize="body-lg">
            Easily prove to auditors that data access controls are in place and enforced. TileDB Cloud SaaS logs every interaction with your
            data and code, all centralized in the TileDB Cloud console with human-readable summaries for easy inclusion in security
            attestations and compliance reports.
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default SaasDataGovernance;
