import React from 'react';
import './Pricing.scss';
import { Link } from 'gatsby';
import ClassName from '@utils/helpers/ClassName';
import Container from '@components/Container';
import Typography from '@components/Typography';
import Button from '@components/Button';
import links from '@constants/links';
import TasksPricing from './assets/tasks_pricing.inline.svg';
import DataPricing from './assets/data_pricing.inline.svg';
import JupyterNotebooksPricing from './assets/jupyter_notebooks_pricing.inline.svg';
import BuildingsMobile from './assets/enterprise-mobile.svg';

interface Props {
  className?: string;
}

const Pricing: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <>
      <div id="pricing" />
      <div className={ClassName.join('TDB-SaasPage__Pricing', className)}>
        <Container className="TDB-SaasPage__Pricing__Background" />
        <Container className="TDB-SaasPage__Pricing__TopContent">
          <Typography as="h2" fontSize="heading-1" color="neutral-main" fontWeight="bold" className="TDB-SaasPage__Pricing__Title">
            Pricing
          </Typography>
          <Typography as="h3" fontSize="subheading-1" color="brand-100" fontWeight="semi-bold" className="TDB-SaasPage__Pricing__Subtitle">
            Pay only for what you use
          </Typography>
          <div className="TDB-SaasPage__Pricing__Prices">
            <div className="TDB-SaasPage__Pricing__Item">
              <TasksPricing />
              <Typography as="p" color="brand-100" align="center" fontSize="body-lg">
                Time taken for slicing an array, performing a SQL query or computing a UDF task.
              </Typography>
            </div>
            <div className="TDB-SaasPage__Pricing__Item">
              <DataPricing />
              <Typography as="p" color="brand-100" align="center" fontSize="body-lg">
                Amount of data retrieved from the service to your client, not the data processed.
              </Typography>
            </div>
            <div className="TDB-SaasPage__Pricing__Item">
              <JupyterNotebooksPricing />
              <Typography as="p" color="brand-100" align="center" fontSize="body-lg">
                Time you spend running a Jupyter notebook server on the TileDB Cloud service.
              </Typography>
            </div>
          </div>
        </Container>
        <div className="TDB-SaasPage__Pricing__LearnMore">
          <a href={links.pricing} target="_blank" rel="noreferrer noopener">
            <Button primary>Learn more</Button>
          </a>
        </div>
        <div className="TDB-SaasPage__Pricing__Onprem">
          <div className="TDB-SaasPage__Pricing__Onprem__Whiteboard" />
          <Container>
            <div className="TDB-SaasPage__Pricing__Onprem__Buildings--mobile">
              <img alt="enterprise" src={BuildingsMobile} />
            </div>
            <div className="TDB-SaasPage__Pricing__Onprem__Content">
              <Typography
                className="TDB-SaasPage__Pricing__Onprem__Title"
                fontSize="heading-2"
                color="neutral-900"
                as="h3"
                fontWeight="bold"
                align="right"
              >
                Looking for an
                <br className="desktop-br" /> on-prem solution?
              </Typography>
              <Typography className="TDB-SaasPage__Pricing__Onprem__Description" align="right" as="h5">
                TileDB Cloud is available as a customer-hosted instance to address enterprise security policies and governance mandates.
              </Typography>
              <div className="TDB-SaasPage__Pricing__ContactSales">
                <Link to={links.contactSales}>
                  <Button primary to={links.cloudEnterprise}>
                    Learn more
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
          <div className="TDB-SaasPage__Pricing__Onprem__Placeholder" />
        </div>
      </div>
    </>
  );
};

export default Pricing;
