import React from 'react';
import Footer from '@components/Footer';
import IndexLayout from '@layouts/index';
import Hero from '@components/Hero';
import Button from '@components/Button';
import Text from '@components/Text';
import Header from '@components/Header';
import links from '@constants/links';
import CloudInAction from '@components/CloudInAction';
import CloudSaas from './CloudSaas';
import SaasCapabilities from './SaasCapabilities';
import shareImage from './assets/share_saas.jpg';
import heroBackgroundImage from './assets/TileDB_Cloud_SaaS_hero.jpg';
import './SaasPage.scss';
import CloudMarketplace from './CloudMarketplace';
import SaasDataGovernance from './SaasDataGovernance';
import Pricing from './Pricing';

const SaasPage: React.FC = () => {
  return (
    <IndexLayout
      pageName="saas"
      helmet={{
        title: 'TileDB Cloud SaaS - Manage any data, zero infrastructure',
        description:
          'TileDB Cloud SaaS is a universal database built on multi-dimensional arrays that combines serverless compute and secure data sharing for scalable, collaborative analysis.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <Hero
        description="Manage, analyze & collaborate at global scale."
        backgroundImage={heroBackgroundImage}
        gradient="linear-gradient(180deg, rgba(0, 62, 143, 0.18) 0%, rgba(0, 24, 88, 0.174) 100%)"
        title={
          <>
            Minimize costs,
            <br />
            maximize collaboration
          </>
        }
        header={
          <>
            TILEDB CLOUD{' '}
            <Text className="TDB-SaasPage__grad-text" color="accent">
              SaaS
            </Text>
          </>
        }
        actions={
          <>
            <Button to={links.console.signup} primary>
              Get started
            </Button>
          </>
        }
      />

      <CloudSaas />
      <SaasCapabilities />
      <CloudMarketplace />
      <SaasDataGovernance />
      <Pricing />
      <CloudInAction />
      <Footer />
    </IndexLayout>
  );
};

export default SaasPage;
