import * as React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import './SaasCapabilities.scss';
import Button from '@components/Button';
import links from '@constants/links';
import sharingCollaborationIcon from './sharing-collaboration.png';

const SaasCapabilities: React.FC = () => (
  <Container>
    <div className="TDB-SaasCapabilities">
      <div className="TDB-SaasCapabilities__child TDB-SaasCapabilities__child--sharing">
        <div className="TDB-SaasCapabilities__inner-container">
          <div className="TDB-SaasCapabilities__child--sharing-text-wrapper">
            <Typography
              fontSize="heading-2"
              color="brand-800"
              fontWeight="bold"
              as="h3"
              align="left"
              className="mb-8 TDB-SaasCapabilities__sharing-text"
            >
              <Typography fontSize="heading-2" fontWeight="bold" color="brand-600">
                Share
              </Typography>{' '}
              in public, collaborate with anyone
            </Typography>
            <Typography
              className="TDB-SaasCapabilities__sharing-text"
              as="p"
              color="neutral-800"
              fontSize="body-lg"
              fontWeight="medium"
              align="left"
            >
              TileDB Cloud SaaS lets you securely share large datasets alongside the Jupyter notebooks and UDFs that use them, facilitating
              collaboration at any scale. Publish open data and code while crowdsourcing usage costs in a pay-as-you-go model, without the
              fear of surprise infrastructure bills as a data producer or huge download hassles for data consumers, no matter what your role
              is in the data economics model.
            </Typography>
          </div>
          <img className="TDB-SaasCapabilities__sharing-img" src={sharingCollaborationIcon} alt="all data" />
        </div>
      </div>
      <div className="TDB-SaasCapabilities__child TDB-SaasCapabilities__child--half TDB-SaasCapabilities__child--blue">
        <div>
          <Typography fontSize="heading-2" color="neutral-main" fontWeight="bold" as="h3" className="mb-8" align="left">
            Compute at scale, 100% serverless
          </Typography>
          <Typography className="TDB-SaasCapabilities__main-text" as="p" color="neutral-main" fontSize="body-lg" fontWeight="medium">
            TileDB Cloud SaaS deploys compute automatically, no instance upgrades or memory management required. Optimize processing at
            massive scale by distributing operations in task graphs, with each node bound tightly to the lifespan of a user-defined
            function. Run out-of-core computations on large datasets, and pay only for what you use.
          </Typography>
        </div>
        <Button className="TDB-SaasCapabilities__child--blue__link" to={links.pricing} arrow sm>
          See pricing
        </Button>
      </div>
      <div className="TDB-SaasCapabilities__child TDB-SaasCapabilities__child--dark TDB-SaasCapabilities__child--half">
        <div className="TDB-SaasCapabilities__inner">
          <div className="TDB-SaasCapabilities__img-zero-copy" />
          <Typography fontSize="heading-2" color="neutral-main" fontWeight="bold" as="h3" className="mb-8">
            In-place access with zero copy
          </Typography>
          <Typography as="p" color="neutral-main" fontSize="body-lg" fontWeight="medium">
            Your data stays on your own object storage service in an analysis-ready format. TileDB Cloud SaaS does not store any datasets.
            Simply connect your cloud provider storage to TileDB Cloud SaaS, and the cloud-native format of TileDB arrays lets you analyze
            data in-place, without additional indexing or data movement.
          </Typography>
        </div>
      </div>
    </div>
  </Container>
);

export default SaasCapabilities;
